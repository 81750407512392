import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  private disableConsent: boolean = false;

  public disable(): void {
    this.disableConsent = true;
  }

  public enable(): void {
    this.disableConsent = false;
  }

  public isDisabled(): boolean {
    return this.disableConsent;
  }
}