import { Inject, Injectable } from '@angular/core';
import { EnvironmentInterface } from '../../../core/models/environment.interface';
import { ENVIRONMENT } from '../../../core/models/environment.token';
import { ConsentService } from './consent.service';

@Injectable({
  providedIn: 'root',
})
export class ScriptsService {
  public constructor(
    @Inject(ENVIRONMENT) private environment: EnvironmentInterface,
    private consentService: ConsentService
  ) {}

  
  public Init(): Promise<void> {
    return new Promise((resolve, reject) => {
      let scripts = this.environment.scripts.map((s) => this.createOptanonScript(s.id, s.src, s.optanonClass));

      // Always load OneTrust script but conditionally initialize it later
      let oneTrustScript = this.createOneTrustScript();
      scripts.push(oneTrustScript);

      this.loadScripts(scripts)
        .then(() => {
          this.initializeOneTrustIfNeeded();
          resolve();
        })
        .catch(() => {
          console.error('Error loading script');
          reject();
        });
    });
  }

  private createOneTrustScript(): HTMLScriptElement {
    const pathArray = window.location.pathname.split('/');
    const countryCode = pathArray[2];
    const dataDomainScript = this.environment.oneTrustDomainId;
    const OneTrust = {};
    OneTrust['geolocationResponse'] = { countryCode: countryCode };
    (window as any).OneTrust = OneTrust;
    console.log('OneTrust object: ', JSON.stringify(OneTrust, null, 2));

    const oneTrustScript = window.document.createElement('script');
    oneTrustScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    oneTrustScript.setAttribute('type', 'text/javascript');
    oneTrustScript.setAttribute('charset', 'UTF-8');
    oneTrustScript.setAttribute('data-document-language', 'true');
    oneTrustScript.setAttribute('data-domain-script', dataDomainScript);
    oneTrustScript.setAttribute('data-manual-init', 'true'); // Use manual initialization

    return oneTrustScript;
  }

  private createOptanonScript(id: string, src: string, optanon: string): HTMLScriptElement {
    const script = window.document.createElement('script');
    script.id = id;
    script.src = src;
    script.setAttribute('type', 'text/plain');
    script.setAttribute('class', optanon);
    return script;
  }

  private async loadScripts(scripts: HTMLScriptElement[]) {
    const promiseArray = scripts.map((s) => this.loadScript(s));
    await Promise.all(promiseArray);
  }

  private loadScript(script: HTMLScriptElement): Promise<void> {
    return new Promise((resolve) => {
      script.onload = () => resolve();
      window.document.head.appendChild(script);
    });
  }

  public async initializeOneTrustIfNeeded(): Promise<void> {
    if (!this.consentService.isDisabled()) {
      if (window.OneTrust && typeof window.OptanonWrapper === 'function') {
        await window.OptanonWrapper();
      }
    }
  }
}